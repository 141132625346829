<template>
  <div class="my-8">
    <div class="flex justify-between">
      <h1 class="text-xl font-bold">Settings</h1>
    </div>

    <div class="my-5 flex items-center">
      <div class="mr-2">Developer mode</div>
      <label class="switch">
        <input
          type="checkbox"
          :value="data.developer_mode"
          :checked="data.developer_mode"
        />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="flex flex-col mt-4">
      <div class="mb-6">
        <label>API KEY</label>
        <div class="shadow border flex rounded-md w-3/4">
          <!-- The text field -->
          <input
            class="w-11/12 rounded-l-md px-4 py-2"
            type="text"
            readonly
            :value="data.apiKey"
            id="apiKey"
          />

          <!-- The button used to copy the text -->
          <button class="bg-gray-200 px-4 py-2" @click="copyApiKey()">
            Copy
          </button>
        </div>
      </div>

      <div class="mb-6">
        <label>SECRET</label>
        <div class="shadow border flex rounded-md w-3/4">
          <!-- The text field -->
          <input
            class="w-11/12 rounded-l-md px-4 py-2"
            type="text"
            readonly
            :value="data.apiSecret"
            id="apiSecret"
          />

          <!-- The button used to copy the text -->
          <button class="bg-gray-200 px-4 py-2" @click="copyApiSecret()">
            Copy
          </button>
        </div>
      </div>

      <button class="w-60 bg-blue-400 px-4 py-2 rounded">
        Generate new keys
      </button>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import moment from 'vue-moment';

export default {
  props: ["data"],

  data: () => ({}),

  methods: {
    copyApiSecret() {
      /* Get the text field */
      var copyText = document.getElementById("apiSecret");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      // /* Alert the copied text */
      // alert("Copied the text: " + copyText.value);
    },

    copyApiKey() {
      /* Get the text field */
      var copyText = document.getElementById("apiKey");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      // /* Alert the copied text */
      // alert("Copied the text: " + copyText.value);
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>